import React, { useRef, useImperativeHandle } from 'react'
import {grey, red} from "../../../Utils/styleConfig";

export const StripeInput = React.forwardRef(
    ({ component: Component, ...props }, ref) => { // Forward the ref here
        const elementRef = useRef();
        useImperativeHandle(ref, () => ({
            focus: () => elementRef.current.focus
        }));
        return (
            <Component
                onReady={element => (elementRef.current = element)}
                {...props}
                options={{
                    style: {
                        base: {
                            fontSize: '16px', // Adjust font size
                            // color: '#000000', // Adjust text color
                            '::placeholder': {
                                // color: grey, // Adjust placeholder color
                            },
                            padding: '10px', // Adjust padding
                            display: 'flex',
                            alignItems: 'center', // Center text vertically
                            height: '100%', // Ensure the component takes full height
                        },
                        invalid: {
                            color: red, // Adjust invalid state color
                        },
                    },
                }}
            />
        )
    }
);