import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {StripeInput} from "./stripeInput";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import Amex from "../../images/cards/Amex.png";
import Diners from "../../images/cards/Diners.png";
import Discover from "../../images/cards/Discover.png";
import Jcb from "../../images/cards/Jcb.png";
import Visa from "../../images/cards/Visa.png";
import UnionPay from "../../images/cards/Unionpay.png";
import Mastercard from "../../images/cards/Mastercard.png";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

export const CreditCardSection = ({cbHandleNameOnCard}) => {

    const handleNameOnCard = (event) => {
        cbHandleNameOnCard(event?.target?.value);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 5px',
            gap: '20px'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                alignItems: 'center',
            }}>
                <Box sx={{borderRadius: '5px', backgroundColor: '#F79327', padding: '5px'}}>
                    <Typography sx={{fontSize: '10px', fontWeight: '600'}}>
                        Powered by Stripe
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: '5px'
                }}>
                    <img src={Visa} alt="VisaLogo" height={15}/>
                    <img src={Mastercard} alt="MasterCardLogo" height={15}/>
                    <img src={Amex} alt="AmexLogo" height={15}/>
                    <img src={UnionPay} alt="UnionPayLogo" height={15}/>
                    <img src={Discover} alt="DiscoverLogo" height={15}/>
                    <img src={Diners} alt="DinersLogo" height={15}/>
                    <img src={Jcb} alt="JcbLogo" height={15}/>
                </Box>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                Card Number
                            </Typography>
                        </FormLabel>
                        <TextField
                            name="ccnumber"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardNumberElement,
                                },
                            }}
                            size='small'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                Expiration Date (MM/YY)
                            </Typography>
                        </FormLabel>
                        <TextField
                            name="ccexp"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardExpiryElement
                                },
                            }}
                            size='small'
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                CVC
                            </Typography>
                        </FormLabel>
                        {/*<CardCvcElement/>*/}
                        <TextField
                            name="cvc"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps: {
                                    component: CardCvcElement
                                },
                            }}
                            size='small'
                        />
                    </FormControl>
                </Grid>
                {/* Address Element */}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>
                            <Typography sx={{fontSize: '14px', fontWeight: '600'}}>
                                Name On Card
                            </Typography>
                        </FormLabel>
                        <TextField
                            name="Name On Card"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            onInput={handleNameOnCard}
                            // InputProps={{
                            //     inputComponent: StripeInput,
                            // }}
                            size='small'
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
