import {
    Button,
    Container,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Modal,
    Select,
    Snackbar,
    Stack
} from "@mui/material";
import Box from "@mui/material/Box";
import {ReactComponent as SchedulePickupIcon} from "../../../images/NewDesignIcon/SchedulePickup.svg";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {
    carrierLogo, checkAppointmentList, checkAppointmentListFromSelectOrder,
    extractFullAddress,
    getMissingKeysForCreateLabelLocation, getMissingKeysForPickupInformation,
    mapCarrierLogo
} from "../../../Utils/Helper";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import dayjs from "dayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {value} from "lodash/seq";
import {SchedulePickup} from "./SchedulePickup";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {isValid} from "date-fns";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import addressIcon from "../../../images/Address.png";
import HomeIcon from "@mui/icons-material/Home";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";
import Paper from "@mui/material/Paper";
import {useThrottle} from "../../../Utils/CustomHook";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import getProvinceCodeByName, {getUSProvinceCodeByName} from "../../../Utils/getProvinceCode";
import {EndUserSchedulePickupPayment} from "./EndUserSchedulePickupPayment";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    SchedulePickupRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    SchedulePickupHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    SchedulePickupTitle: {
        fontSize: '16px',
        textAlign: 'left'
    },
    SchedulePickupText: {
        fontSize: '16px',
        textAlign: 'left'
    },
    SchedulePickupTextGreen: {
        fontSize: '16px',
        textAlign: 'left',
        color: '#1D8B45',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelShipFromModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        maxHeight: 400,
        backgroundColor: '#FFFFFF',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        padding: '40px 20px',
        overflow: 'auto'
    },
    ShippingPackageCreateLabelShipFromSelectBox: {
        border: '1px solid #000000',
        borderRadius: '5px',
        padding: '10px',
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
    },
    ShippingPackageCreateLabelShipFromAddressText: {
        fontSize: '14px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const EndUserSchedulePickup = () => {

    const history = useHistory();
    const classes = useStyles();
    const accessToken = getAccessToken("access_token");

    const [appointmentId, setAppointmentId] = useState(null);
    const [bookedAppointList, setBookAppointList] = useState([]);
    const [emailValidation, setEmailValidation] = useState(true);
    const [sendEmailOpen, setSendEmailOpen] = useState(false);
    const [sendEmailLoading, setSendEmailLoading] = useState(false);
    const [pickupDetails, setPickupDetails] = useState(null);
    const [initPickupLoading, setInitPickupLoading] = useState(false);
    const [pickupInformationMissing, setPickupInformationMissing] = useState(null);
    const [carrierMissing, setCarrierMissing] = useState(null);
    const [orderListMissing, setOrderListMissing] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [availability, setAvailability] = useState(null);
    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [searchAddressList, setSearchAddressList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [open, setOpen] = useState(false);
    const [carrier, setCarrier] = useState(null);
    const [trackingList, setTrackingList] = useState([]);
    const [trackingNumbers, setTrackingNumbers] = useState('');
    const [orderList, setOrderList] = useState([]);
    const [selectOrder, setSelectOrder] = useState([]);
    const [UUCOrderList, setUUCOrderList] = useState([]);
    const [pickupInformation, setPickupInformation] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: "",
        phone: null,
        email: "",
        company: "",
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        },
        pickupPoint: 'Front Door',
        isResidential: true,
        pickupDate: dayjs(new Date()),
        pickupReadyTime: dayjs('2022-04-17T11:00'),
        pickupCloseTime: dayjs('2022-04-17T16:00'),
        isPickup: 1,
        remarkNotes: ''
    });
    const throttledAddress = useThrottle(pickupInformation?.address, 1500);

    const handleSendEmailDialogClose = () => {
        setSendEmailOpen(false);
        // setContactEmail('');
    };

    const handleSendEmailDialogOpen = () => {
        setSendEmailOpen(true);
        // setContactEmail();
        // setContactEmail(userInfo?.email);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbSetAppointmentId = (id) => {
        setAppointmentId(id);
    }

    const getTrackingList = async () => {

        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getRecentTrackingRecordsList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                params: {
                    accountBase: carrier?.serviceName
                }
            })
            console.log('get tracking list result', result);
            setTrackingList(result?.data);

            const groupedData = result?.data.reduce((acc, value) => {
                // Check if the array already has the key
                if (!acc[value.uuc_order_id]) {
                    acc[value.uuc_order_id] = [];
                }
                acc[value.uuc_order_id].push(value);
                return acc;
            }, {});
            setUUCOrderList(groupedData);
            const groupedArray = Object.values(groupedData).reverse();
            console.log('get tracking list groupedArray', groupedArray);
            setOrderList(groupedArray);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const handleSchedulePickupRequest = async (errorMessage) => {
        setSendEmailLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/canparPickup`;

        const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYYMMDD");
        const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HHmm');
        const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HHmm');

        let data = {
            pickupDateInfo: {
                readyTime: pickupReadyTime,
                closeTime: pickupCloseTime,
                pickupDate: pickupDate
            },
            pickupAddress: {
                address: pickupInformation?.address,
                addressTwo: pickupInformation?.address2,
                city: pickupInformation?.city,
                province: pickupInformation?.province?.code,
                country: pickupInformation?.country,
                postalCode: pickupInformation?.postalCode?.replace(/\s/g, ""),
                pickupPoint: pickupInformation?.pickupPoint,
                isResidential: pickupInformation?.isResidential
            },
            contact: {
                name: pickupInformation?.name,
                companyName: pickupInformation?.company || pickupInformation?.name,
                phone: pickupInformation?.phone,
                email: pickupInformation?.email,
                phoneExtension: ""
            },
            trackingNumbers: trackingNumbers,
            errorMessage: errorMessage || 'N/A'
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log(result);
            setErrorMessage('');
            setSuccessMessage('Successfully Send Pickup Request');
            handleSuccessMessage();
            setSendEmailLoading(false);
            handleSendEmailDialogClose();
        } catch (e) {
            console.log('e', e.response);
            setErrorMessage('Failed to Send Pickup Request. Try again another time.');
            handleErrorMessage();
            setSendEmailLoading(false);
        }
    }

    const initSchedulePickupAppointment = async () => {

        setInitPickupLoading(true);
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/initSchedulePickupAppointment`;

        try {
            const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYYMMDD");
            const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HHmm');
            const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HHmm');

            let data = {
                pickupDateInfo: {
                    readyTime: pickupReadyTime,
                    closeTime: pickupCloseTime,
                    pickupDate: pickupDate,
                },
                pickupAddress: {
                    address: pickupInformation?.address,
                    addressTwo: pickupInformation?.address2,
                    city: pickupInformation.city,
                    province: pickupInformation?.province?.code,
                    country: pickupInformation?.country,
                    postalCode: pickupInformation?.postalCode?.replace(/\s*/g, ""),
                    pickupPoint: pickupInformation?.pickupPoint,
                    isResidential: pickupInformation?.isResidential,
                },
                contact: {
                    name: pickupInformation.name,
                    companyName: pickupInformation.name,
                    phone: pickupInformation?.phone,
                    email: pickupInformation?.email,
                    phoneExtension: pickupInformation?.ext || "",
                },
                trackingNumbers: trackingNumbers,
                accountBase: carrier?.serviceName,
                remarkNotes: pickupInformation?.remarkNotes,
                existingAppointmentId: appointmentId
            }

            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                data: data
            })

            console.log('[initSchedulePickupAppointment] result', result);
            setErrorMessage('');
            if (!appointmentId) {
                setSuccessMessage('Successfully get pickup rate.');
                handleSuccessMessage();
            }
            setPickupDetails(result?.data);
        } catch (e) {
            console.log('[initSchedulePickupAppointment] error', e?.response);
            setErrorMessage(`${e.response.data.error}. Fail to get pickup rate.`);
            handleErrorMessage();
            setPickupDetails(null);
        } finally {
            setInitPickupLoading(false)
        }
    }

    const handleAvailability = async () => {
        try {
            let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/checkPickupAvailability`;

            const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYY-MM-DD");
            const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HH:mm');
            const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HH:mm');
            const currentLocalTime = dayjs()?.format('YYYY-MM-DD HH:mm:ss')

            let data = {
                accountBase: carrier?.serviceName,
                serviceType: carrier?.serviceType,
                postalCode: pickupInformation?.postalCode?.replace(/\s+/g, ''),
                country: pickupInformation?.country,
                pickupDate: pickupDate,
                pickupReadyTime: pickupReadyTime,
                pickupCloseTime: pickupCloseTime,
                currentLocalTime: currentLocalTime
            }

            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log('result', result);
            console.log('[BusinessSchedulePickup] handleAvailability - result', result);
            setAvailability(result?.data);
            if (result?.data?.code === 200) {
                return true
            } else {
                return false
            }
        } catch (e) {
            console.log('error', e);
            setAvailability(e?.response);
            return false
        }
    }

    const verifyAddressIsResidentialPickup = async (address) => {
        if (!address || address === "") {
            return false;
        }
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: address,
            city: pickupInformation?.city,
            province: pickupInformation?.province?.code,
            postalCode: pickupInformation?.postalCode,
            country: pickupInformation?.country
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // console.log('result', result);
            return result?.data?.result;
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        console.log('[googleMapsPlaceAutocomplete] country', country);
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const searchAddress = async (searchString) => {
        let requestURL = `${NODE_ROUTE_URI}/elasticsearch/addressBook/searchEndUserAddressBook`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    role: 'SENDER',
                    queryString: searchString
                }
            })

            console.log('result', result?.data);
            const searchAddressResult = result?.data;
            // setSearchNameList(result?.data);
            return searchAddressResult;

        } catch (e) {
            console.log('error', e.response)
        }
    }

    const getShipFromAddress = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/sender`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            setAddressList(result.data.result);

        } catch (e) {
            console.log(e.response);
        }
    }

    const handleChangeCarrier = (event, value) => {
        if (value) {
            console.log(value);
            setCarrier(value);
            setSelectOrder([]);
            setTrackingNumbers('');
        } else {
            setSelectOrder([]);
            setTrackingNumbers('');
        }
        console.log('selectOrder should be empty:', selectOrder); // This might still show old value due to async nature of setState
    };

    const handleSelectOrder = (event, value) => {
        // console.log('[EndUserSchedulePickup] handleSelectUUCOrder', value);
        const appointList = checkAppointmentListFromSelectOrder(value);
        // console.log('[EndUserSchedulePickup] appointList', appointList);
        setBookAppointList(appointList);
        setSelectOrder(value);
        const trackingNumbers = value.flat().map(item => item.tracking_no).join('\n');
        // console.log('[EndUserSchedulePickup] trackingNumbers', trackingNumbers);
        setTrackingNumbers(trackingNumbers);
        // console.log('[EndUserSchedulePickup] status', appointList?.[0]?.[0]?.status);

        if (appointList?.length > 0) {
            if (+appointList?.[0]?.[0]?.status !== 1) {
                setAppointmentId(appointList?.[0]?.[0]?.appoint_id);
            } else {
                setAppointmentId(null);
            }
        } else {
            setAppointmentId(null);
        }
    }

    const isWeekend = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        console.log('tomorrow', tomorrow);

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        console.log('tomorrow', tomorrow);

        return dayjs(tomorrow);
    };

    const handleDateChange = (date) => {
        // console.log('data', date);
        if (date) {
            const valid = isValid(date?.$d);
            if (valid) {
                // const dateString = date.toISOString().split('T')[0];
                setPickupInformation(prevState => ({
                    ...prevState,
                    pickupDate: date
                }))
            } else {
                setPickupInformation(prevState => ({
                    ...prevState,
                    pickupDate: date
                }))
                // const defaultData = getNextWeekday();
                // setSelectedDate(defaultData);
            }
        } else {
            const defaultData = getNextWeekday();
            setPickupInformation(prevState => ({
                ...prevState,
                pickupDate: defaultData
            }))
            // setSelectedDate(defaultData);
        }
    };

    const handleEarliestPickupTime = (newValue) => {
        if (newValue) {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupReadyTime: newValue,
            }))
            // setEarliestPickupTime(newValue);
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupReadyTime: dayjs('2022-04-17T11:00'),
            }))
            // setEarliestPickupTime(dayjs('2022-04-17T11:00'));
        }
    }

    const handleLatestPickupTime = (newValue) => {
        if (newValue) {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupCloseTime: newValue,
            }))
            // setLatestPickupTime(newValue);
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupCloseTime: dayjs('2022-04-17T16:00'),
            }))
            // setLatestPickupTime(dayjs('2022-04-17T16:00'));
        }
    }

    const handleSelectAddress = (address) => {
        console.log('select address', address);
        setPickupInformation(prevState => ({
            ...prevState,
            postalCode: address?.postal_code,
            province: {
                code: address?.province,
                name: address?.province
            },
            city: address?.city,
            address: address?.address,
            address2: address?.address_2,
            country: address?.country,
            displayAddress: {
                description: `${address?.address}, ${address?.city}, ${address?.province}, ${address?.country}`,
                placeId: null,
                text: address?.address
            },
            name: address?.name,
            phone: address?.phone,
            email: address?.address_email,
            company: address?.company_name,
            saveAddress: false,
            addressList: address
        }))
        handleClose();
    }

    const handleNewAddress = () => {
        history.push('/address-book');
    }

    const handleInputChangeName = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            name: value,
            addressList: {
                ...prevState.addressList,
                name: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeName = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                name: ""
            }))
        }
    }

    const handleInputChangePhone = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            phone: value,
            addressList: {
                ...prevState.addressList,
                phone: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.phone.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangePhone = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                phone: ""
            }))
        }
    }

    const handleInputChangeEmail = async (event, value) => {
        console.log('value', value);
        setPickupInformation(prevState => ({
            ...prevState,
            email: value,
            addressList: {
                ...prevState.addressList,
                address_email: value,
            }
        }))
        if (value) {
            const result = await searchAddress(value);
            const filterResult = result.filter(item => item.address_email.toLowerCase().includes(value.toLowerCase()));
            console.log('filter data', filterResult);
            setSearchAddressList(filterResult);
        }
    }

    const handleChangeEmail = (event, value) => {
        console.log('value', value);
        if (value) {
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: value?.postal_code,
                province: {
                    code: value?.province,
                    name: value?.province
                },
                city: value?.city,
                address: value?.address,
                address2: value?.address_2,
                country: value?.country,
                displayAddress: {
                    description: `${value?.address}, ${value?.city}, ${value?.province}, ${value?.country}`,
                    placeId: null,
                    text: value?.address
                },
                name: value?.name,
                phone: value?.phone,
                email: value?.address_email,
                company: value?.company_name,
                addressList: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                email: ""
            }))
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setPickupInformation(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', pickupInformation?.country);
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleCity = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handlePickupPoint = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            pickupPoint: event?.target?.value
        }))
    }

    const handleIsResidential = event => {
        const isResidential = event?.target.value ? true : false;
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: isResidential
        }))
    }

    const handleRemarkNotes = event => {
        const value = event?.target?.value;
        if (value.length <= 30) {
            setPickupInformation(prevState => ({
                ...prevState,
                remarkNotes: value
            }));
        }
    }

    const handleSelectPickup = () => {
        setAvailability(prevState => ({
            ...prevState,
            code: 200
        }))
        setPickupInformation(prevState => ({
            ...prevState,
            pickupDate: dayjs(`${availability?.pickupDate}T11:00`),
            pickupReadyTime: dayjs(`${availability?.pickupDate}T${availability?.pickupReadyTime}`),
            pickupCloseTime: dayjs(`${availability?.pickupDate}T${availability?.pickupCloseTime}`)
        }))
        // setSelectedDate(dayjs(`${availability?.pickupDate}T11:00`));
        // setEarliestPickupTime(dayjs(`${availability?.pickupDate}T${availability?.pickupReadyTime}`));
        // setLatestPickupTime(dayjs(`${availability?.pickupDate}T${availability?.pickupCloseTime}`));
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const pickupInformationMissing = getMissingKeysForPickupInformation({
            name: pickupInformation?.name,
            phone: pickupInformation?.phone,
            email: pickupInformation?.email,
            address: pickupInformation?.address,
            city: pickupInformation?.city,
            province: pickupInformation?.province?.code,
            country: pickupInformation?.country,
            postalCode: pickupInformation?.postalCode
        })

        const emailValid = await validateEmailAddress(pickupInformation?.email);

        setEmailValidation(emailValid);
        setPickupInformationMissing(pickupInformationMissing);
        setCarrierMissing(!carrier);
        setOrderListMissing(selectOrder?.length === 0);

        !carrier && setErrorMessage(prevState => prevState + ' Carrier is missing.');
        selectOrder?.length === 0 && setErrorMessage(prevState => prevState + ' Order List is empty.');
        pickupInformationMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Pickup Information is missing one or more required fields.');
        !emailValid && setErrorMessage(prevState => prevState + ' Email address is invalid.');

        if (pickupInformationMissing.length === 0 &&
            carrier &&
            selectOrder?.length !== 0 &&
            emailValid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleConfirmAvailability = async () => {
        const validationResult = await validate();
        if (validationResult) {
            await handleAvailability()
        }
    }

    const handleGetPickupRate = async () => {
        const validationResult = await validate();
        if (validationResult) {
            const availabilityResult = await handleAvailability()
            if (availabilityResult) {
                await initSchedulePickupAppointment();
            }
        }
    }

    const handleSchedulePickup = async () => {
        const validationResult = await validate();
        if (validationResult) {
            const availabilityResult = await handleAvailability()
            if (availabilityResult) {
                handleSendEmailDialogOpen();
            }
        }
    }

    const handleEdit = () => {
        setPickupDetails(null);
    }

    useEffect(() => {
        (
            async () => getTrackingList()
        )()
    }, [carrier]);

    useEffect(() => {
        getShipFromAddress();
    }, []);

    useEffect(async () => {
        const result = await verifyAddressIsResidentialPickup(throttledAddress);
        console.log('[verifyAddressIsResidentialPickup] is residential', result);
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: result
        }))
        // setPickupResidence(result);
    }, [throttledAddress]);

    // console.log('[carrier]', carrier);
    // console.log('[getTrackingList] trackingList', trackingList);
    // console.log('[EndUserSchedulePickup] orderList', orderList);
    // console.log('[getTrackingList] selectOrder', selectOrder);
    // console.log('[getTrackingList] uucOrderList', UUCOrderList);
    // console.log('[EndUserSchedulePickup] pickupInformation', pickupInformation);
    // console.log('[EndUserSchedulePickup] bookedAppointList', bookedAppointList);
    console.log('[EndUserSchedulePickup] appointment id', appointmentId);

    return (
        <Container maxWidth='xl'>
            <Dialog
                open={sendEmailOpen}
                onClose={handleSendEmailDialogClose}
            >
                <DialogTitle>
                    Send the Request to Schedule Pickup
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            UUCargo will handle your pickup request for you with Canpar. You will receive the
                            confirmation email from op@uucargo.ca. Please click 'Confirm' to proceed.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSendEmailDialogClose}>
                        No
                    </Button>
                    <LoadingButton
                        onClick={handleSchedulePickupRequest}
                        loading={sendEmailLoading}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.SchedulePickupRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <SchedulePickupIcon width={25} height={25}/>
                    <Typography style={styles.SchedulePickupHeading}>
                        Schedule a Pickup
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '0 40px',
                    gap: '40px',
                    display: 'flex',
                }}>
                    <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Select a shipping courier
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                // multiple
                                                options={carrierLogo}
                                                getOptionLabel={option => option?.displayName}
                                                fullWidth
                                                onChange={handleChangeCarrier}
                                                // filterSelectedOptions
                                                disabled={pickupDetails}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    error={carrierMissing}
                                                    helperText={
                                                        (carrierMissing) && intl.get('CROSS_BORDER.REQUIRED')
                                                    }
                                                />}
                                                renderOption={(option) => (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <img src={option?.url}
                                                                 alt='logo'
                                                                 width='30px'
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography>
                                                                {option?.displayName}
                                                            </Typography>
                                                        </Grid>
                                                        {/*<Grid item xs={4}>*/}
                                                        {/*    <Typography sx={{color: '#1D8B45', fontWeight: '600'}}>*/}
                                                        {/*        {option?.serviceName === 'CANPAR' && 'Free'}*/}
                                                        {/*    </Typography>*/}
                                                        {/*</Grid>*/}
                                                    </Grid>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Search Orders
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                key={selectOrder.length} // Change key to force re-render
                                                value={selectOrder}
                                                multiple
                                                options={orderList}
                                                getOptionLabel={(option) => {
                                                    const label = option?.[0]?.order_id;
                                                    // console.log("Label being set:", label);
                                                    return `${label}`;
                                                }}
                                                onChange={handleSelectOrder}
                                                filterSelectedOptions
                                                disabled={pickupDetails}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    error={orderListMissing}
                                                    helperText={
                                                        (orderListMissing) && intl.get('CROSS_BORDER.REQUIRED')
                                                    }
                                                />}
                                                renderOption={(option) => (
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={2}>
                                                            <Typography>
                                                                {option?.[0]?.order_id}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                height: '100%',
                                                                alignItems: 'center'
                                                            }}>
                                                                <img
                                                                    src={mapCarrierLogo(option?.[0]?.account_base?.toUpperCase())}
                                                                    alt="logo"
                                                                    width='25px'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography sx={styles.SchedulePickupText}>
                                                                {option?.[0]?.send_to_name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography sx={styles.SchedulePickupText}>
                                                                {option?.[0]?.send_to_city}, {option?.[0]?.send_to_province}, {option?.[0]?.send_to_country}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.SchedulePickupTitle}>
                                                    Order
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.SchedulePickupTitle}>
                                                    Carrier
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={styles.SchedulePickupTitle}>
                                                    Recipient
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={styles.SchedulePickupTitle}>
                                                    Tracking Number
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={styles.SchedulePickupTitle}>
                                                    Date
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    maxHeight: '30vh',
                                                    overflowY: 'auto'
                                                }}>
                                                    <Grid container spacing={1}>
                                                        {
                                                            selectOrder?.length > 0 ?
                                                                selectOrder?.map((order, index) => (
                                                                    <Fragment key={index}>
                                                                        <Grid item xs={2}>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                height: '100%',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                <Typography
                                                                                    sx={styles.SchedulePickupTextGreen}>
                                                                                    {order?.[0]?.order_id}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                height: '100%',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                <img src={carrier?.url} alt="logo"
                                                                                     width='45px'/>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Typography
                                                                                sx={styles.SchedulePickupText}>
                                                                                {order?.[0]?.send_to_name}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={styles.SchedulePickupText}>
                                                                                {order?.[0]?.send_to_city}, {order?.[0]?.send_to_province}, {order?.[0]?.send_to_country}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                height: '100%',
                                                                                justifyContent: 'center',
                                                                                flexDirection: 'column'
                                                                            }}>
                                                                                {
                                                                                    order?.map((tracking, index) => (
                                                                                        <Typography
                                                                                            sx={styles.SchedulePickupText}
                                                                                            key={index}>
                                                                                            {tracking?.tracking_no}
                                                                                        </Typography>
                                                                                    ))
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <Box sx={{
                                                                                display: 'flex',
                                                                                height: '100%',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                <Typography
                                                                                    sx={styles.SchedulePickupText}>
                                                                                    {dayjs(order?.[0]?.created_at).format('YYYY-MM-DD')}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Fragment>
                                                                ))
                                                                :
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        There are no order available for pickup.
                                                                    </Typography>
                                                                </Grid>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {
                            bookedAppointList?.length > 0 &&
                            <Grid item md={12} xs={12}>
                                <Typography>
                                    The following shows that the selected orders have already been booked or initialed.
                                    If the order has a confirmation number, you can contact the
                                    carrier for pickup details. The schedule details are as follows:
                                </Typography>
                                <Grid container spacing={0}>
                                    {
                                        bookedAppointList?.map((appoint, index) => (
                                            <Fragment key={index}>
                                                <Grid item md={4} xs={12}>
                                                    <Typography>
                                                        Appointment Id: {appoint?.[0]?.appoint_id}
                                                    </Typography>
                                                    <Typography>
                                                        Confirm Number: {appoint?.[0]?.confirm_appoint_no || 'N/A'}
                                                    </Typography>
                                                    <Typography>
                                                        Appointment Date: {appoint?.[0]?.appoint_date}
                                                    </Typography>
                                                    <Typography>
                                                        Status: {+appoint?.[0]?.status === 0 ? 'Initialed' : +appoint?.[0]?.status === 100 ? 'Inactive' : +appoint?.[0]?.status === 1 && 'Submitted'}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        }
                        <Grid item md={12} xs={12}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Pickup Date and Time
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Pickup Date
                                            </FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    shouldDisableDate={isWeekend}
                                                    value={pickupInformation?.pickupDate}
                                                    onChange={handleDateChange}
                                                    disablePast
                                                    disabled={pickupDetails}
                                                    renderInput={(props) =>
                                                        <TextField
                                                            {...props}
                                                            fullWidth
                                                        />}
                                                    slotProps={{
                                                        textField: {
                                                            helperText: !isValid(pickupInformation?.pickupDate?.$d) && 'Invalid Pickup Date',
                                                            size: 'small'
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Earliest Pickup Time
                                            </FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    onChange={handleEarliestPickupTime}
                                                    value={pickupInformation?.pickupReadyTime}
                                                    maxTime={dayjs().hour(16).minute(0).second(0)}
                                                    minTime={dayjs().hour(7).minute(0).second(0)}
                                                    disabled={pickupDetails}
                                                    renderInput={(props) =>
                                                        <TextField
                                                            {...props}
                                                            fullWidth
                                                        />}
                                                    slotProps={{
                                                        textField: {
                                                            helperText: !isValid(pickupInformation?.pickupReadyTime?.$d) && 'Invalid Pickup Earliest Time',
                                                            size: 'small'
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Latest Pickup Time
                                            </FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    onChange={handleLatestPickupTime}
                                                    value={pickupInformation?.pickupCloseTime}
                                                    maxTime={dayjs().hour(19).minute(0).second(0)}
                                                    minTime={dayjs().hour(10).minute(0).second(0)}
                                                    disabled={pickupDetails}
                                                    renderInput={(props) =>
                                                        <TextField
                                                            {...props}
                                                            fullWidth
                                                        />}
                                                    slotProps={{
                                                        textField: {
                                                            helperText: !isValid(pickupInformation?.pickupCloseTime?.$d) && 'Invalid Pickup Latest Time',
                                                            size: 'small'
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <Typography>
                                                Pickup Information and Location
                                            </Typography>
                                            <Stack direction='row' spacing={0} alignItems='center'>
                                                <Button
                                                    onClick={handleOpen}
                                                    disabled={pickupDetails}
                                                    startIcon={<img src={addressIcon} width='30px'/>}>
                                                    <Typography sx={{
                                                        fontSize: '14px',
                                                        textAlign: 'left',
                                                        color: '#609966',
                                                        fontWeight: '600',
                                                        textTransform: 'none'
                                                    }}>
                                                        Address Book
                                                    </Typography>
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.NAME')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={pickupInformation?.addressList}
                                                fullWidth
                                                options={searchAddressList}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.name}
                                                disabled={pickupDetails}
                                                onInputChange={handleInputChangeName}
                                                onChange={handleChangeName}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={pickupInformationMissing?.includes('name')}
                                                    helperText={
                                                        (pickupInformationMissing?.includes('name')) && intl.get('CROSS_BORDER.REQUIRED')
                                                    }
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option?.name} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.PHONE')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={pickupInformation?.addressList}
                                                fullWidth
                                                options={searchAddressList}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.phone}
                                                disabled={pickupDetails}
                                                onInputChange={handleInputChangePhone}
                                                onChange={handleChangePhone}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={pickupInformationMissing?.includes('phone')}
                                                    helperText={
                                                        (pickupInformationMissing?.includes('phone')) && intl.get('CROSS_BORDER.REQUIRED')
                                                    }
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option?.phone} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.EMAIL')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={pickupInformation?.addressList}
                                                fullWidth
                                                options={searchAddressList}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.address_email}
                                                disabled={pickupDetails}
                                                onInputChange={handleInputChangeEmail}
                                                onChange={handleChangeEmail}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={pickupInformationMissing?.includes('email') || !emailValidation}
                                                    helperText={
                                                        (pickupInformationMissing?.includes('email')) ? intl.get('CROSS_BORDER.REQUIRED') :
                                                            !emailValidation && intl.get('LANDING_PAGE.SIGN_UP.INVALID_EMAIL')
                                                    }
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option?.phone} | {option?.address}, {option?.city}, {option?.province}, {option?.country}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.ADDRESS')}
                                            </FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                value={pickupInformation?.displayAddress}
                                                fullWidth
                                                options={shipFromAddressPredictions}
                                                filterOptions={(options, state) => options}
                                                getOptionLabel={option => option?.text}
                                                disabled={pickupDetails}
                                                onInputChange={handleInputChangeAddress}
                                                onChange={handleChangeAddress}
                                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                                renderInput={params => <TextField
                                                    {...params}
                                                    className={classes.smallInput}
                                                    variant="outlined"
                                                    error={pickupInformationMissing?.includes('address')}
                                                    helperText={
                                                        (pickupInformationMissing?.includes('address')) && intl.get('CROSS_BORDER.REQUIRED')
                                                    }
                                                />}
                                                renderOption={option => (
                                                    <Typography style={{fontSize: '12px'}}>
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {intl.get('SHIPPING_INFORMATION.ADDRESS_2')}
                                            </FormLabel>
                                            <TextField
                                                value={pickupInformation?.address2}
                                                fullWidth
                                                variant='outlined'
                                                onInput={handleAddress2}
                                                size='small'
                                                disabled={pickupDetails}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.CITY')}
                                            </FormLabel>
                                            <TextField
                                                value={pickupInformation?.city}
                                                fullWidth
                                                variant='outlined'
                                                onInput={handleCity}
                                                disabled={pickupDetails}
                                                size='small'
                                                error={pickupInformationMissing?.includes('city')}
                                                helperText={
                                                    (pickupInformationMissing?.includes('city')) && intl.get('CROSS_BORDER.REQUIRED')
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.PROVINCE')}
                                            </FormLabel>
                                            <TextField
                                                value={pickupInformation?.province?.code}
                                                fullWidth
                                                variant='outlined'
                                                onInput={handleProvince}
                                                disabled={pickupDetails}
                                                size='small'
                                                error={pickupInformationMissing?.includes('province')}
                                                helperText={
                                                    (pickupInformationMissing?.includes('province')) && intl.get('CROSS_BORDER.REQUIRED')
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.COUNTRY')}
                                            </FormLabel>
                                            <Select
                                                value={pickupInformation?.country}
                                                size='small'
                                                onChange={handleCountry}
                                                disabled={pickupDetails}
                                                error={pickupInformationMissing?.includes('country')}
                                            >
                                                <MenuItem value='CA'>Canada</MenuItem>
                                                <MenuItem value='US'>Unites States</MenuItem>
                                            </Select>
                                            <FormHelperText
                                                sx={{
                                                    color: "error.main",
                                                }}
                                            >
                                                {(pickupInformationMissing?.includes('country')) && intl.get('CROSS_BORDER.REQUIRED')}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                {intl.get('SHIPPING_INFORMATION.POSTAL_CODE')}
                                            </FormLabel>
                                            <TextField
                                                value={pickupInformation?.postalCode}
                                                fullWidth
                                                variant='outlined'
                                                onInput={handlePostalCode}
                                                disabled={pickupDetails}
                                                size='small'
                                                error={pickupInformationMissing?.includes('postalCode')}
                                                helperText={
                                                    (pickupInformationMissing?.includes('postalCode')) && intl.get('CROSS_BORDER.REQUIRED')
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Pickup Location
                                            </FormLabel>
                                            <Select
                                                value={pickupInformation?.pickupPoint}
                                                onChange={handlePickupPoint}
                                                disabled={pickupDetails}
                                                size='small'
                                            >
                                                <MenuItem value={"Front Door"}>Front Door</MenuItem>
                                                <MenuItem value={"Rear Door"}>Rear Door</MenuItem>
                                                <MenuItem value={"Side Door"}>Side Door</MenuItem>
                                                <MenuItem value={"None"}>None</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <FormLabel required>
                                                Is Residential
                                            </FormLabel>
                                            <Select
                                                value={pickupInformation?.isResidential ? 1 : 0}
                                                onChange={handleIsResidential}
                                                disabled={pickupDetails}
                                                size='small'
                                            >
                                                <MenuItem value={1}>Yes</MenuItem>
                                                <MenuItem value={0}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <FormLabel>
                                                {intl.get('SCHEDULE_PICKUP.REMARK_NOTE')}
                                            </FormLabel>
                                            <TextField
                                                value={pickupInformation?.remarkNotes}
                                                fullWidth
                                                variant='outlined'
                                                onInput={handleRemarkNotes}
                                                disabled={pickupDetails}
                                                size='small'
                                            />
                                            <FormHelperText sx={{textAlign: 'right'}}>
                                                30 characters maximum
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {
                                        availability?.code === 200 &&
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                padding: '10px',
                                                border: '2px solid #1D8B45',
                                                borderRadius: '5px',
                                            }}>
                                                <Typography sx={{textAlign: 'center', color: '#1D8B45'}}>
                                                    The selected pickup date and time is available.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    }
                                    {
                                        availability?.code === 201 &&
                                        <>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    padding: '15px',
                                                    border: '2px solid #FF8911',
                                                    borderRadius: '5px',
                                                    backgroundColor: '#FFD6A5',
                                                }}>
                                                    <Typography>
                                                        Unfortunately, there are <span style={{fontWeight: '600'}}>no pickups available from the carrier at this time.</span> Please
                                                        see the available times below.
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={{fontWeight: '600'}}>
                                                    Next Pickup Available
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    padding: '15px',
                                                    border: '2px solid #1D8B45',
                                                    borderRadius: '5px',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Date: </span>{availability?.pickupDate}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Ready Time : </span>{availability?.pickupReadyTime}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%'
                                                            }}>
                                                                <Typography>
                                                <span
                                                    style={{fontWeight: '600'}}>Close Time : </span>{availability?.pickupCloseTime}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end'
                                                            }}>
                                                                <Button
                                                                    variant='outlined'
                                                                    sx={{
                                                                        border: '2px solid #1D8B45',
                                                                        "&:hover": {
                                                                            border: '2px solid #1D8B45',
                                                                            filter: 'brightness(0.9)'
                                                                        }
                                                                    }}
                                                                    onClick={handleSelectPickup}
                                                                >
                                                                    <Typography sx={{
                                                                        textTransform: 'none',
                                                                        fontWeight: '600',
                                                                        color: '#1D8B45',
                                                                    }}>
                                                                        Select Pickup
                                                                    </Typography>
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Box sx={{
                                            display: 'flex',
                                            gap: '10px'
                                        }}>
                                            <LoadingButton
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={handleConfirmAvailability}
                                                disabled={pickupDetails}
                                            >
                                                <Typography sx={{
                                                    textTransform: 'none',
                                                    color: '#1D8B45',
                                                    fontSize: '14px',
                                                    fontWeight: '600'
                                                }}>
                                                    Confirm Availability
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    // onClick={carrier?.serviceName === 'CANPAR' ? handleSchedulePickup : pickupDetails ? handleEdit : handleGetPickupRate}
                                    onClick={pickupDetails ? handleEdit : handleGetPickupRate}
                                    loading={initPickupLoading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        {
                                            pickupDetails ? 'Edit' : carrier?.serviceName === 'CANPAR' ? "Schedule Pickup" : appointmentId ? 'Update Pickup' : 'Get Pickup Rate'
                                        }
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {
                pickupDetails &&
                <EndUserSchedulePickupPayment pickupDetails={pickupDetails}
                                              handleSchedulePickupRequest={handleSchedulePickupRequest}
                                              handleEdit={handleEdit}
                                              appointmentId={appointmentId}
                                              cbSetAppointmentId={cbSetAppointmentId}/>
            }
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={styles.ShippingPackageCreateLabelShipFromModalBox}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', minWidth: 400}}>
                                <Typography style={{fontSize: '20px'}}>
                                    My Address List
                                </Typography>
                                <Button
                                    color='success'
                                    onClick={handleNewAddress}
                                >
                                    New Address
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                    }}>
                        {
                            addressList?.length > 0 ? addressList.map((address, index) =>
                                    (
                                        <Box
                                            sx={styles.ShippingPackageCreateLabelShipFromSelectBox}
                                            key={index}
                                            onClick={() => handleSelectAddress(address)}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                        <HomeIcon color='success'/>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                            {address?.name}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                            {address?.address_2 && `${address?.address_2} - `}{address?.address}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                            {address?.city}, {address?.province}, {address?.country} {address?.postal_code}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                            {address?.address_email}
                                                        </Typography>
                                                        <Typography
                                                            style={styles?.ShippingPackageCreateLabelShipFromAddressText}>
                                                            {address?.phone}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    ))
                                : <Grid item xs={12}>
                                    <Typography>
                                        No Addresses
                                    </Typography>
                                </Grid>
                        }
                    </Box>
                </Box>
            </Modal>
        </Container>
    )

}